import React, { memo, useState, useEffect, useMemo } from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";
import * as turf from "@turf/turf";
import { feature } from "topojson-client";
import topojsonKeys from "../../../../data/TopojsonKeys.json";
import HorizontalBarChart from "./HorizontalBar";

const MapChart = ({
  countryCode,
  topojson,
  selectedRegion,
  regions,
  level,
  setTooltipContent,
  selectRegion,
  setSubRegion,
  regionReports,
  restrictedRegions,
}) => {
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState();
  const mapDimensions = {
    width: 800,
    height: 800,
  };
  console.log("regionReports", regionReports);
  const topojsonKey = useMemo(() => {
    if (topojson) {
      return Object.keys(topojson.objects)[0];
    }
  }, [topojson]);

  useEffect(() => {
    const geojson = feature(topojson, topojsonKey);
    if (topojsonKey === "USA") {
      setCenter([-123.329416, 48.51491]);
    } else {
      const centroid = turf.center(geojson);
      setCenter(centroid.geometry.coordinates);
    }
    const bbox = turf.bbox(geojson);
    const bounds = [
      [bbox[1], bbox[0]],
      [bbox[3], bbox[2]],
    ];
    const dx = bounds[1][0] - bounds[0][0];
    const dy = bounds[1][1] - bounds[0][1];
    const biggestEdge = Math.max(dx, dy);
    const getZoom = (bigEdge) => {
      console.log("bigEdge", bigEdge);
      if (bigEdge >= 100) {
        return 2;
      }
      if (bigEdge < 100 && bigEdge >= 90) {
        return 3;
      }
      if (bigEdge < 90 && bigEdge >= 80) {
        return 3.5;
      }
      if (bigEdge < 80 && bigEdge >= 70) {
        return 4.5;
      }
      if (bigEdge < 70 && bigEdge >= 60) {
        return 5.5;
      }
      if (bigEdge < 60 && bigEdge >= 50) {
        return 6.5;
      }
      if (bigEdge < 50 && bigEdge >= 40) {
        return 7.5;
      }
      if (bigEdge < 40 && bigEdge >= 30) {
        return 8;
      }
      if (bigEdge < 30 && bigEdge >= 20) {
        return 9.5;
      }
      if (bigEdge < 20 && bigEdge >= 15) {
        return 10;
      }
      if (bigEdge < 15 && bigEdge >= 12.5) {
        return 13;
      }
      if (bigEdge < 12.5 && bigEdge >= 10) {
        return 17;
      }
      if (bigEdge < 10 && bigEdge >= 7.5) {
        return 22;
      }
      if (bigEdge < 7.5 && bigEdge >= 5) {
        return 32;
      }
      if (bigEdge < 5 && bigEdge >= 4) {
        return 42;
      }
      if (bigEdge < 4 && bigEdge >= 3) {
        return 50;
      }
      if (bigEdge < 3 && bigEdge >= 2) {
        return 65;
      }
      if (bigEdge < 2 && bigEdge >= 1.5) {
        return 100;
      }
      if (bigEdge < 1.5 && bigEdge >= 1) {
        return 150;
      }
      if (bigEdge < 1 && bigEdge >= 0.5) {
        return 250;
      }
      if (bigEdge < 0.5 && bigEdge >= 0.25) {
        return 400;
      }
      if (bigEdge < 0.25 && bigEdge >= 0) {
        return 800;
      }
    };

    const zoom = getZoom(biggestEdge);
    setZoom(zoom);
  }, [topojson, topojsonKey]);

  const maxLevel = useMemo(() => {
    return +topojsonKeys[countryCode].split("_")[2];
  }, [countryCode]);

  return (
    <>
      <ComposableMap
        width={mapDimensions.width}
        height={mapDimensions.height}
        data-tip=""
        projectionConfig={{ scale: 200 }}
      >
        <ZoomableGroup filterZoomEvent={true} center={center} zoom={zoom}>
          <Geographies geography={topojson}>
            {({ geographies }) =>
              geographies.map((geo) => {
                // console.log(geo.properties);
                const hasPins =
                  regions.indexOf(geo.properties[`GID_${+level + 1}`]) !== -1 &&
                  restrictedRegions.indexOf(
                    geo.properties[`GID_${+level + 1}`]
                  ) === -1;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={
                      hasPins
                        ? () => {
                            if (level + 1 === maxLevel) {
                              // setSubRegion(geo.properties[`GID_${+level + 1}`]);
                              return null;
                            } else {
                              selectRegion(
                                geo.properties[`GID_${+level + 1}`].replace(
                                  /\./g,
                                  "-"
                                ),
                                geo.properties
                              );
                            }
                          }
                        : () => {}
                    }
                    onMouseEnter={() => {
                      if (geo && geo.properties[`GID_${+level + 1}`]) {

                        // if there is NO data to show
                        if (!regionReports || !regionReports[
                          geo.properties[`GID_${+level + 1}`]
                        ]) {
                          setTooltipContent(
                            <div>
                              <h3>{`${
                                geo.properties[`NL_NAME_${+level + 1}`]
                                  ? `${
                                      geo.properties[`NL_NAME_${+level + 1}`]
                                    } / `
                                  : " "
                              }${geo.properties[`NAME_${+level + 1}`]}`}</h3>
                            </div>
                          );
                          return
                        }

                        // if there IS data to show
                        const {accepted, delivered, have, rejected, need, unfinished, bibles } = regionReports[
                          geo.properties[`GID_${+level + 1}`]
                        ];
                        const totalLocations =
                          (accepted || 0) +
                          (delivered || 0) +
                          (have || 0) +
                          (rejected || 0) +
                          (need || 0) +
                          (unfinished || 0)
                        setTooltipContent(
                          <div>
                            <h3>{`${
                              geo.properties[`NL_NAME_${+level + 1}`]
                                ? `${
                                    geo.properties[`NL_NAME_${+level + 1}`]
                                  } / `
                                : " "
                            }${geo.properties[`NAME_${+level + 1}`]}`}</h3>
                            {regionReports &&
                              regionReports[
                                geo.properties[`GID_${+level + 1}`]
                              ] && (
                                <>
                                  <p>
                                    Bibles Shared:{" "}
                                    {bibles.toLocaleString()}
                                  </p>
                                  <p>
                                    Locations Visited:{" "}
                                    {totalLocations.toLocaleString()}
                                  </p>
                                  <div
                                    style={{
                                      maxWidth: 400,
                                      backgroundColor: "white",
                                      borderRadius: 5,
                                      paddingRight: 10,
                                      marginBottom: 20,
                                    }}
                                  >
                                    <HorizontalBarChart
                                      data={
                                        regionReports[
                                          geo.properties[`GID_${+level + 1}`]
                                        ]
                                      }
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          // `${
                          //   geo.properties[`NL_NAME_${+level + 1}`]
                          //     ? `${geo.properties[`NL_NAME_${+level + 1}`]} / `
                          //     : " "
                          // }${geo.properties[`NAME_${+level + 1}`]}${
                          //   level + 1 === maxLevel &&
                          //   regionReports[geo.properties[`GID_${+level + 1}`]]
                          //     ? ` (${regionReports[
                          //         geo.properties[`GID_${+level + 1}`]
                          //       ].bibles.toLocaleString()})`
                          //     : ""
                          // }`
                        );
                      }
                    }}
                    onMouseLeave={() => {
                      if (geo && geo.properties[`GID_${+level + 1}`]) {
                        setTooltipContent("");
                      }
                    }}
                    style={{
                      default: {
                        fill: hasPins ? "#1F79BC" : "#D6D6DA",
                        outline: hasPins ? "#004689" : "black",
                      },
                      hover: {
                        fill: "#004689",
                        outline: "none",
                      },
                      // pressed: {
                      //   fill: "#004689",
                      //   outline: "none",
                      // },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
